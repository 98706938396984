<script setup lang="ts">
    import { InvoiceStatus } from '@containex/portal-backend-dto';
    import { computed } from 'vue';
    import Chip from 'primevue/chip';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        state: InvoiceStatus;
    }>();

    const { t } = useI18n();

    const invoiceData = computed(() => {
        switch (props.state) {
            case InvoiceStatus.Paid:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.PAID'),
                    icon: 'pi pi-check-circle',
                    class: 'chip paid-chip',
                };
            case InvoiceStatus.Partially:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.PARTIALLY'),
                    icon: 'pi pi-exclamation-circle',
                    class: 'chip partially-chip',
                };
            case InvoiceStatus.Open:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.OPEN'),
                    icon: 'pi pi-times-circle',
                    class: 'chip open-chip',
                };
        }
        return {};
    });
</script>

<template>
    <Chip :label="invoiceData.label" :icon="invoiceData.icon" :class="invoiceData.class" />
</template>

<style scoped lang="scss">
    @use '../../styling/main';

    .chip {
        color: main.$vt-c-white;
        border-radius: main.$spacing-3;
    }

    .paid-chip {
        background-color: main.$color-success-green;
    }

    .partially-chip {
        background-color: main.$color-orange;
    }

    .open-chip {
        background-color: main.$color-error-red;
    }
</style>
