import { useInvoiceStore } from '@/account/stores/invoices';
import { computed, type ComputedRef } from 'vue';
import { InvoiceDto, InvoiceStatus, PaginationStep } from '@containex/portal-backend-dto';
import { invoicesApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';
import useGlobalToast from '@/composables/useGlobalToast';

export interface InvoiceQuery {
    invoices: ComputedRef<InvoiceDto[]>;
    openTotal: ComputedRef<number>;
    totalInvoiceAmount: ComputedRef<number>;
}

export interface InvoiceAction {
    fetchInvoices(invoiceAmount: number, step: PaginationStep, invoiceStatus?: InvoiceStatus): Promise<void>;
}

export function useInvoiceQuery(): InvoiceQuery {
    const store = useInvoiceStore();

    return {
        invoices: computed(() => store.invoices),
        openTotal: computed(() => store.openTotal),
        totalInvoiceAmount: computed(() => store.totalInvoiceAmount),
    };
}

export function useInvoiceAction(): InvoiceAction {
    const store = useInvoiceStore();

    return {
        async fetchInvoices(invoiceAmount: number, step: PaginationStep, invoiceStatus?: InvoiceStatus): Promise<void> {
            try {
                const invoices = store.invoices;
                let pageCursor;

                if (step === PaginationStep.New || invoices == null) {
                    pageCursor = null;
                } else {
                    const filterInvoice = step === PaginationStep.Next ? invoices[invoices.length - 1] : invoices[0];
                    pageCursor = {
                        id: filterInvoice?.id,
                        date: filterInvoice?.invoiceDate.toLocaleString(),
                    };
                }

                const response = await invoicesApi.fetchInvoices(httpClient, {
                    invoiceAmount: String(invoiceAmount),
                    invoiceStatus,
                    step,
                    pageCursorId: pageCursor?.id,
                    pageCursorDate: pageCursor?.date,
                });

                if (response.data != null) {
                    store.invoices = response.data.invoices;
                    store.totalInvoiceAmount = response.data.totalInvoiceAmount;
                    store.openTotal = response.data.openTotal;
                }
            } catch {
                const { errorToastOptions, addToast } = useGlobalToast();

                store.invoices = [];
                store.totalInvoiceAmount = 0;
                store.openTotal = 0;

                addToast(errorToastOptions);
            }
        },
    };
}
