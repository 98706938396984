<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { useInvoiceAction, useInvoiceQuery } from '@/account/composables/invoice';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import SelectButton, { type SelectButtonChangeEvent } from 'primevue/selectbutton';
    import { type FetchPaginatedDataDto, InvoiceStatus, PaginationStep } from '@containex/portal-backend-dto';
    import { useI18n } from 'vue-i18n';
    import { ref } from 'vue';
    import { parseISO } from 'date-fns';
    import BlockUI from 'primevue/blockui';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
    import { useDateFormatter } from '@/composables/date-format';
    import { immediatelyDownloadFile } from '@/util/download';
    import useGlobalToast from '@/composables/useGlobalToast';
    import InvoiceChip from '@/account/components/InvoiceChip.vue';
    import { invoicesApi } from '@containex/portal-backend-api-client';
    import { httpClient } from '@/common/api/http-client';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import { getLogger } from '@containex/logger';

    const { t } = useI18n();
    const { invoices, openTotal, totalInvoiceAmount } = useInvoiceQuery();
    const invoiceAction = useInvoiceAction();
    const { formatPrice } = usePriceFormatter();
    const { dateFormatter } = useDateFormatter();
    const logger = getLogger('UserList');

    const selectedFilter = ref(undefined);
    const filterOptions = ref([
        { label: t('INVOICE.INVOICE_STATUS_TYPE.ALL'), value: undefined },
        { label: t('INVOICE.INVOICE_STATUS_TYPE.OPEN'), value: InvoiceStatus.Open },
        { label: t('INVOICE.INVOICE_STATUS_TYPE.PARTIALLY'), value: InvoiceStatus.Partially },
        { label: t('INVOICE.INVOICE_STATUS_TYPE.PAID'), value: InvoiceStatus.Paid },
    ]);

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await invoiceAction.fetchInvoices(paginatorCurrentRows.value, PaginationStep.New, selectedFilter.value);
    }).perform();

    const fetchPaginatedInvoices = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('InvoiceHistoryView', 'fetching invoices for table');
        isLoading.value = true;
        await invoiceAction.fetchInvoices(data.dataAmount, data.step, selectedFilter.value);
        isLoading.value = false;
    }).drop();

    async function handleFilterChange(event: SelectButtonChangeEvent): Promise<void> {
        await fetchPaginatedInvoices.perform({
            dataAmount: paginatorCurrentRows.value,
            step: PaginationStep.New,
        });
        paginatorCurrentPage.value = 0;
        getPageLinkSize();
    }

    async function downloadPreSignedPdf(invoiceId: string): Promise<void> {
        try {
            const response = await invoicesApi.downloadInvoice(httpClient, invoiceId);
            if (response.data?.url != null) {
                const url = response.data.url;
                await immediatelyDownloadFile(url, invoiceId);
            } else if (response.data?.url == null) {
                throw Error('URL must not be null');
            }
        } catch {
            const { errorToastOptions, addToast } = useGlobalToast();
            addToast({
                ...errorToastOptions,
                summary: 'ERROR.DOWNLOAD_FILE.SUMMARY',
                detail: 'ERROR.DOWNLOAD_FILE.DETAIL',
            });
        }
    }

    async function handlePaginatorChange(event: DataTablePageEvent): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchPaginatedInvoices.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchPaginatedInvoices.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalInvoiceAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>

<template>
    <div class="invoice-history-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('INVOICE.HISTORY') }}</h2>
        <div v-if="task.isSuccessful && invoices.length !== 0" class="open-total-container">
            <div>{{ t('INVOICE.OPEN_TOTAL', { value: formatPrice(openTotal) }) }}</div>
        </div>
        <SelectButton
            v-model="selectedFilter"
            option-label="label"
            option-value="value"
            :options="filterOptions"
            :allow-empty="false"
            aria-labelledby="basic"
            @change="handleFilterChange"
        />
        <div v-if="task.isSuccessful">
            <BlockUI :blocked="fetchPaginatedInvoices.isRunning">
                <DataTable
                    v-if="invoices.length > 0 || fetchPaginatedInvoices.isRunning"
                    class="invoice-table"
                    lazy
                    :value="invoices"
                    paginator
                    :total-records="totalInvoiceAmount"
                    :rows="paginatorCurrentRows"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :loading="isLoading"
                    :page-link-size="paginatorPageLinkSize"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    paginator-template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                >
                    <Column field="id" :header="t('INVOICE.INVOICE_NUMBER')" />
                    <Column field="invoiceDate" :header="t('INVOICE.DATE_INVOICE')">
                        <template #body="slotProps">
                            {{ dateFormatter.formatDate(parseISO(slotProps.data.invoiceDate)) }}
                        </template>
                    </Column>
                    <Column field="invoiceStatus" :header="t('INVOICE.INVOICE_STATUS')">
                        <template #body="slotProps">
                            <InvoiceChip :state="slotProps.data.invoiceStatus" />
                        </template>
                    </Column>
                    <Column field="orderId" :header="t('ORDER.ORDER_NUMBER')" />
                    <Column field="dateDue" :header="t('INVOICE.DATE_DUE')">
                        <template #body="slotProps">
                            {{ dateFormatter.formatDate(parseISO(slotProps.data.dateDue)) }}
                        </template>
                    </Column>
                    <Column field="amountDue" :header="t('INVOICE.AMOUNT_TOTAL')">
                        <template #body="slotProps">
                            {{ formatPrice(slotProps.data.amountTotal) }}
                        </template>
                    </Column>
                    <Column field="amountDue" :header="t('INVOICE.AMOUNT_DUE')">
                        <template #body="slotProps">
                            {{ formatPrice(slotProps.data.amountDue) }}
                        </template>
                    </Column>
                    <Column field="document">
                        <template #body="slotProps">
                            <div>
                                <Button
                                    v-if="slotProps.data.pdfLink?.trim()"
                                    outlined
                                    icon="pi pi-download"
                                    @click="downloadPreSignedPdf(slotProps.data.id)"
                                />
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <p v-else>
                    {{ t('INVOICE.NO_INVOICES') }}
                </p>
            </BlockUI>
        </div>
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .invoice-history-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .open-total-container {
        display: flex;
        column-gap: main.$spacing-5;
        font-weight: 500;
    }

    .invoice-table {
        margin-top: main.$spacing-5;
    }

    :deep(.p-selectbutton .p-button.p-highlight) {
        background: main.$color-background-lightblue;
        border-color: main.$color-border-darkgray;
        color: main.$ctx-primary-color;
    }
</style>
