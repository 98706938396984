import { defineStore } from 'pinia';
import { InvoiceDto } from '@containex/portal-backend-dto';

interface InvoiceState {
    invoices: InvoiceDto[];
    openTotal: number;
    totalInvoiceAmount: number;
}

export const useInvoiceStore = defineStore('invoice', {
    state: (): InvoiceState => ({
        invoices: [],
        openTotal: 0,
        totalInvoiceAmount: 0,
    }),
});
